<template>
	<div v-loading.fullscreen.lock="fullscreenLoading" class="pdf-images">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_1.jpg">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_2.jpg">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_3.jpg">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_4.jpg">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_5.jpg">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_6.jpg">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_7.jpg">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_8.jpg">
	</div>
</template>

<script>
export default {
	data() {
		return {
			fullscreenLoading: false
		};
	},
	mounted() {
		this.openFullScreen1();
	},
	methods: {
		openFullScreen1() {
			this.fullscreenLoading = true;
			setTimeout(() => {
				this.fullscreenLoading = false;
			}, 1000);
		},
	}
};
</script>

<style scoped="scoped">
.pdf-images{
	width: 100%;
	float: left;
}
.pdf-images img{
	width: 100%;
	float: left;
}
</style>
